import React, { MouseEventHandler, useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import {
  TextTitleThreeEmphasized,
  TextBodyEmphasized,
} from './ui/typographies';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import CanvasSVG from '../../../components/CanvasSVG/CanvasSVG';


const prependCSS = (canvas: string, css: string) => {
  let prependedCanvas = canvas;
  if (css && css !== '') {
    prependedCanvas = `${css}">${canvas}`;
  }
  return prependedCanvas;
};

interface Props {
  isOpen: boolean;
  projectInfo: {
    title: string;
    image?: string;
    canvas?: {
      canvas: string;
      canvasCss: string;
      canvasBBoxX: number;
      canvasBBoxY: number;
      canvasBBoxWidth: number;
      canvasBBoxHeight: number;
    };
    description: string;
    createdAt: string;
  };
  handleClose: MouseEventHandler<HTMLButtonElement>;
  handleConfirm: MouseEventHandler<HTMLButtonElement>;
}

export const DeleteModal: React.FC<Props> = ({
  isOpen,
  projectInfo,
  handleClose,
  handleConfirm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const formattedDate = format(new Date(projectInfo.createdAt), "d 'de' MMMM 'de' yyyy", {
    locale: es,
  });

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={isLoading}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '480px',
          width: '100%',
          height: 'fit-content',
          maxHeight: '500px',
          paddingX: theme.spacing(5),
          paddingY: theme.spacing(4),
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <TextTitleThreeEmphasized>
            {intl.formatMessage(
              {
                id: 'creabots.dashboard.deleteModal.title',
              },
              { project: `"${projectInfo.title}"` }
            )}
          </TextTitleThreeEmphasized>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={2}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <TextBodyEmphasized textAlign="center">
              {intl.formatMessage({
                id: 'creabots.dashboard.deleteModal.description',
              })}
            </TextBodyEmphasized>
            <Box display="flex" alignItems="center" width="100%">
              <Box
                sx={{
                  backgroundColor: '#F8F8F6',
                  width: '200px',
                  margin: '16px',
                  borderRadius: '6px',
                }}
              >
                {projectInfo.image ? (
                  <img
                    src={projectInfo.image}
                    alt={projectInfo.description}
                    style={{
                      width: '100%',
                      objectFit: 'contain',
                      aspectRatio: '16/9',
                    }}
                  />
                ) : (
                  ''
                )}
                {projectInfo.canvas ? (
                  <CanvasSVG
                    content={prependCSS(projectInfo.canvas.canvas, projectInfo.canvas.canvasCss)}
                    svgX={projectInfo.canvas.canvasBBoxX || 10}
                    svgY={projectInfo.canvas.canvasBBoxY || 10}
                    svgWidth={projectInfo.canvas.canvasBBoxWidth || 110}
                    svgHeight={projectInfo.canvas.canvasBBoxHeight || 108}
                    width="100%"
                    height="80px"
                    iframeHeight="80px"
                  />
                ) : (
                  ''
                )}
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <TextBodyEmphasized>
                  {projectInfo.title}
                </TextBodyEmphasized>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: '1rem',
                    lineHeight: '24px',
                    fontFamily: theme.typography.fontFamily,
                    textAlign: 'left',
                  })}
                >
                  Creado el: {formattedDate}
                </Typography>
              </Box>
            </Box>
            <Button
              fullWidth
              size="small"
              variant="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'common.cancel',
              })}
            </Button>
            <Button
              fullWidth
              sx={(theme) => ({
                backgroundColor: theme.palette.error.main,
                ":hover": {
                  backgroundColor: theme.palette.error.dark,
                },
              })}
              size="small"
              variant="primary"
              onClick={handleConfirm}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'common.delete',
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
