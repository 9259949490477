import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  DiscreteColorLegend,
  FlexibleXYPlot,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';
import styles from './MLPanel.mod.scss';
import useBluetooth from '@utils/hooks/bluetooth'
import useMachineLearning from '@utils/hooks/machineLearning'
import { convertBase64ToObject, convertObjectToBase64 } from '@utils/hooks/machineLearning/util'
import LinearProgress from '@mui/material/LinearProgress';
import { MODEL_TYPES, TRAIN_STEPS } from '@utils/hooks/machineLearning/params';
import { title } from 'process';
import { pl } from 'date-fns/locale';
import { Steps } from 'intro.js-react';
import { useBlockly } from '@modules/blockly/features/hooks';


const ARDUINO_CONNECTION_SERVICE = '0000ffe0-0000-1000-8000-00805f9b34fb'
const ARDUINO_CONNECTION_CHARACTERISTIC = '0000ffe1-0000-1000-8000-00805f9b34fb'

const MLPanel = React.memo((props) => {
  const intl = useIntl();
  const DebounceState = useRef(0)
  const lastSentCommand = useRef('')
  const mlDataSet = useRef([])
  const plotterData = useRef([])

  const [commandClass, setCommandClass] = useState('MLClass')
  const [stopCommandClass, setStopCommandClass] = useState('MLC_Stop')
  const [videoWrapper, setVideoWrapper] = useState('canvas-wrapper')
  const [confidenceLevel, setConfidenceLevel] = useState(70)
  const [messageDelay, setMessageDelay] = useState(300)
  const [defaultClassCount, setDefaultClassCount] = useState(5)

  const [step, setStep] = useState(TRAIN_STEPS.TEACH)
  const [cameraAllowed, setCameraAllowed] = useState(false)
  const [samplesCount, setSamplesCount] = useState({})
  const [confidences, setConfidences] = useState({})
  const [toggleVideo, setToggleVideo] = useState(true)
  const [importedModel, setImportedModel] = useState(null)
  const [loading, setLoading] = useState(null)
  const [modelType, setModelType] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [modelTrained, setModelTrained] = useState(false);
  const [modelManualImported, setModelManualImported] = useState(false);
  const [trainingParams, setTrainingParams] = useState({
    epochs: 0,
    learningRate: 0,
    batchSize: 0,
  });
  const [trainingData, setTrainingData] = useState({
    epoch: 0,
    acc: 0,
    loss: 0,
    val_acc: 0,
    val_loss: 0,
  });
  const [importedJsonFile, setImportedJsonFile] = useState(null);
  const [importedBinFile, setImportedBinFile] = useState(null);
  const [hasBluetooth, setHasBluetooth] = useState(props.board?.bluetooth || false);
  const [steps, setSteps] = useState(null);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const blocklyState = useBlockly();

  const { requestConnection, sendMessage, connected } = useBluetooth({
    serviceID: ARDUINO_CONNECTION_SERVICE,
    characteristicID: ARDUINO_CONNECTION_CHARACTERISTIC,
  });

  const getPrediction = (detectionConfidences) => {
    setConfidences(detectionConfidences);
    let classDetected = false;
    if (Object.keys(detectionConfidences).length > 0) {
      mlDataSet.current.map((data) => {
        if (detectionConfidences[data[0]] && (detectionConfidences[data[0]] || 0) * 100 >= confidenceLevel) {
          classDetected = true;
          if (Date.now() - DebounceState.current > messageDelay) {
            DebounceState.current = Date.now();
            console.log('================message', data[1]);
            if (hasBluetooth) {
              sendMessage(data[1]);
            }
            lastSentCommand.current = data[1];
          }
        }
      });
    }
    if (!classDetected && lastSentCommand.current !== stopCommandClass) {
      console.log('================message', stopCommandClass);
      if (hasBluetooth) {
        sendMessage(stopCommandClass);
      }
      lastSentCommand.current = stopCommandClass;
    }
  }
  const {
    init,
    getDefaultTrainingParams,
    stopVideo,
    startVideo,
    addSample,
    getSamples,
    setSamples,
    clearAllSamples,
    startClassifying,
    stopClassifying,
    train,
    exportModel,
    importModel,
    loadModel,
  } = useMachineLearning({
    trainingParams,
    wrapperClass: videoWrapper,
    getPredictionCallback: getPrediction,
  });

  const tutorialOptions = {
    options: {
      showButtons: false,
      showStepNumbers: false,
      // exitOnOverlayClick: false,
      // disableInteraction: true,
      showBullets: false,
      overlayOpacity: 0,
    },
    steps: [
      {
        element: '[aria-posinset="12"]',
        intro: intl.formatMessage({ id: 'machineLearning.tutorial.step0' }),
        position: 'right',
        tooltipClass: styles.stepsTooltipClass,
        highlightClass: styles.stepsHighlightClass,
        hintAnimation: true,
      },
      {
        element: `[role="blocklyBuild"]`,
        intro: intl.formatMessage({ id: 'machineLearning.tutorial.step1' }),
        position: 'top',
        tooltipClass: styles.stepsTooltipClass,
        highlightClass: styles.stepsHighlight2Class,
      },
      {
        element: '#MLConnectBluetooth',
        intro: intl.formatMessage({ id: 'machineLearning.tutorial.step2' }),
        position: 'top',
        tooltipClass: styles.stepsTooltipClass,
        highlightClass: styles.stepsHighlight2Class,
      },
      {
        element: '#MLModifyTraining',
        intro: intl.formatMessage({ id: 'machineLearning.tutorial.step3' }),
        position: 'bottom',
        tooltipClass: styles.stepsTooltipClass,
        highlightClass: styles.stepsHighlight2Class,
      },
    ],
  };

  const stepsOnBeforeExit = (stepIndex) => {
    if (stepIndex !== steps.props.steps.length - 1) {
      // steps.introJs.nextStep();
      return false;
    } else {
      return true;
    }
  }

  const stepsOnExit = (stepIndex) => {
    setStepsEnabled(false);
    sessionStorage.setItem('hideToolTipML', true);
    return true;
  }

  const onToggleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const changeStep = (newStep) => {
    if (!loading) {
      setStep(newStep);
      if (newStep === TRAIN_STEPS.CLASSIFY) {
        // if (!connected && hasBluetooth) {
        //   requestConnection()
        // }
        if (!toggleVideo) {
          startVideo();
          setToggleVideo(true);
        }
        startClassifying();
      } else if (newStep === TRAIN_STEPS.TRAIN) {
        stopVideo();
        setToggleVideo(false);
        setModelTrained(false);
        setModelManualImported(false);
        stopClassifying();
      } else if (newStep === TRAIN_STEPS.TEACH) {
        // clearAllSamples();
        // setSamplesCount({});
        setTrainingData({
          epoch: 0,
          acc: 0,
          loss: 0,
          val_acc: 0,
          val_loss: 0,
        });
        plotterData.current = [];
        setModelTrained(false);
        stopClassifying();
        if (modelManualImported) {
          setDefaultClasses();
          setModelManualImported(false);
        }
        if (!toggleVideo) {
          startVideo();
          setToggleVideo(true);
        }
      }
    }
  }

  const takeSample = async (className) => {
    setLoading('Tomando muestra ...');
    const nSamples = { ...samplesCount };
    addSample(className).then((count) => {
      nSamples[className] = count;
      setSamplesCount(nSamples);
      setLoading(null);
    });
  }

  const renderClassContainers = () => {
    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={styles.classContainer}>
          <div className={styles.label}>
            <label>{data[0]}</label>
          </div>
          <button onClick={() => takeSample(data[0])} disabled={(loading)}>Tomar muestra ({samplesCount[data[0]] || 0})</button>
        </div>
      );
    });
    return containers;
  };

  const renderPredictionContainers = () => {

    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={`${styles.classContainer} ${styles.predictionContainer}`}>
          <div className={styles.label}>
            <label>{data[0]}</label>
            <span>{((confidences[data[0]] || 0) * 100).toFixed(2) || 0} %</span>
          </div>
          <LinearProgress className={styles.progress} variant="determinate" value={parseFloat((confidences[data[0]] || 0) * 100)} />
        </div>
      );
    });
    return containers;
  };

  const plot = () => {
    const precision = [];
    const precisionTest = [];
    const loss = [];
    const lossTest = [];
    let maxLoss = 0;
    if (plotterData.current) {
      plotterData.current.map((data) => {
        precision.push({ x: data.epoch, y: data.acc });
        precisionTest.push({ x: data.epoch, y: data.val_acc });
        loss.push({ x: data.epoch, y: data.loss });
        lossTest.push({ x: data.epoch, y: data.val_loss });
        if (data.loss > maxLoss) {
          maxLoss = data.loss;
        }
        if (data.val_loss > maxLoss) {
          maxLoss = data.val_loss;
        }
      });
    }

    return (precision.length === 0) ? null : (
      <div className={styles.graph}>
        <FlexibleXYPlot yDomain={[0, 1]} height={180}>
          <DiscreteColorLegend
            items={[
              { title: 'Precisión', color: "#6ad0ff", strokeWidth: 6 },
              { title: 'Prec. de test', color: "#157dad", strokeWidth: 6 },
            ]}
            orientation="horizontal"
            style={{ fontColor: '#fff' }}
          />
          <VerticalGridLines style={{ opacity: 0.2 }} />
          <HorizontalGridLines style={{ opacity: 0.2 }} />
          <XAxis />
          <YAxis />
          <LineSeries
            color="#6ad0ff"
            key={`${Math.random()}`}
            data={precision}
            style={{ strokeWidth: 2, mark: { stroke: 'white' } }}
          />
          <LineSeries
            color="#157dad"
            key={`${Math.random()}`}
            data={precisionTest}
            style={{ mark: { stroke: 'white' } }}
          />
        </FlexibleXYPlot>
        <FlexibleXYPlot yDomain={[0, maxLoss]} height={180}>
          <DiscreteColorLegend
            items={[
              { title: 'Pérdida', color: "#FF9833", strokeWidth: 6 },
              { title: 'Pérdida de test', color: "#CC3333", strokeWidth: 6 },
            ]}
            orientation="horizontal"
          />
          <VerticalGridLines style={{ opacity: 0.2 }} />
          <HorizontalGridLines style={{ opacity: 0.2 }} />
          <XAxis />
          <YAxis />
          <LineSeries
            color="#FF9833"
            key={`${Math.random()}`}
            data={loss}
            style={{ strokeWidth: 2, mark: { stroke: 'white' } }}
          />
          <LineSeries
            color="#CC3333"
            key={`${Math.random()}`}
            data={lossTest}
            style={{ mark: { stroke: 'white' } }}
          />
        </FlexibleXYPlot>
      </div>
    );
  }

  const handleClearAllSamples = () => {
    setSamplesCount({});
    clearAllSamples();
  };

  const handleToggleVideo = () => {
    setToggleVideo(!toggleVideo);
    if (toggleVideo) {
      stopVideo();
    } else {
      startVideo()
        .then(() => {
          startDataSet();
        })
        .catch((e) => {
          console.error('=============', e);
          setCameraAllowed(false);
          setToggleVideo(false);
        });
    }
  }

  const handleAskCameraPermissions = () => {
    startVideo().then(() => {
      setCameraAllowed(true);
      setToggleVideo(true);
    })
      .catch((e) => {
        console.error('=============', e);
        setCameraAllowed(false);
      });
  }

  const startDataSet = async () => {
    setCameraAllowed(true);
    setToggleVideo(true);
  };

  const handleTrain = () => {
    setLoading('Preparando modelo ...');
    plotterData.current = [];
    trainOnData();
  };

  const trainOnData = async () => {
    await train({
      onTrainBegin: async (logs) => {
        // console.log("train begin: ", logs);
      },
      onTrainEnd: async (logs) => {
        // console.log("train end: ", logs);
      },
      onBatchEnd: async (batch, logs) => {
        // console.log("batch end: ", batch, logs);
      },
      onEpochBegin: async (epoch, logs) => {
        // console.log("Epoch begin: ", epoch, logs);
      },
      onEpochEnd: async (epoch, log) => {
        // console.log("Epoch end: ", epoch, log);
        const trainResults = {
          epoch,
          acc: log.acc || 0,
          loss: log.loss || 0,
          val_acc: log.val_acc || 0,
          val_loss: log.val_loss || 0,
        };
        setTrainingData(trainResults);
        if (plotterData.current) {
          plotterData.current.push(trainResults);
        }
        // if (epoch === trainingParams.epochs - 1) {
        //   setModelTrained(true);
        // }
      },
    });
    setLoading(null);
    setModelTrained(true);
  };

  const setDefaultClasses = () => {
    mlDataSet.current = [];
    for (let i = 1; i <= defaultClassCount; i++) {
      mlDataSet.current.push([`Clase ${i}`, `${commandClass}${i}`]);
    }
  }

  const handleExportSamples = () => {
    const samples = getSamples();
    if (samples) {
      const jsonString = JSON.stringify(convertObjectToBase64(samples), null, 2);
      const blob = new Blob([jsonString], { type: 'text/plain' });
      const link = document.createElement('a');
      link.download = `muestras-educabot-${modelType}.txt`;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleImportSamples = (event) => {
    setLoading('Cargando muestras ...');
    const files = event.target.files;
    if (files[0].name.endsWith('.txt')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const samples = convertBase64ToObject(JSON.parse(e.target.result, null, 2));
        if (samples && mlDataSet.current.length > 0) {
          const nSamples = setSamples(samples, mlDataSet.current);
          if (nSamples) {
            setSamplesCount(nSamples);
          } else {
            setErrorMessage('El archivo no contiene las muestras correctas.');
          }
        }
        setLoading(null);
      };
      reader.readAsText(files[0]);
    }
  }

  const handleOnChangeFile = (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.endsWith('.json')) {
        setImportedJsonFile(files[i]);
      } else if (files[i].name.endsWith('.bin')) {
        setImportedBinFile(files[i]);
      }
    }
  }
  // const handleImportModel = async () => {
  //   setLoading('Importando modelo ...');
  //   clearAllSamples();
  //   setSamplesCount({});
  //   // const importedClasses = await importModel(document.getElementById('file1'), document.getElementById('file2'));
  //   const importedClasses = await importModel(importedJsonFile, importedBinFile);
  //   setLoading(null);
  //   if (importedClasses) {
  //     mlDataSet.current = [];
  //     const classes = Object.keys(importedClasses);
  //     for (let i = 0; i < classes.length; i++) {
  //       mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
  //     }
  //     setModelManualImported(true);
  //     changeStep(TRAIN_STEPS.CLASSIFY);
  //   }
  // }

  useEffect(async () => {
    if (importedJsonFile && importedBinFile) {
      const importedClasses = await importModel(importedJsonFile, importedBinFile);
      if (importedClasses) {
        mlDataSet.current = [];
        const classes = Object.keys(importedClasses);
        for (let i = 0; i < classes.length; i++) {
          mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
        }
        changeStep(TRAIN_STEPS.CLASSIFY);
      }
    }
  }, [importedJsonFile, importedBinFile])

  useEffect(() => {
    if (modelType) {
      setLoading('Preparando la cámara ...');
      init(modelType)
        .then(() => {
          setTrainingParams(getDefaultTrainingParams());
          startDataSet();
          setLoading(null);
          setErrorMessage(null);
        })
        .catch((e) => {
          console.error('=============', e);
          setErrorMessage('No has dado permisos para que usemos la cámara. Por favor revisa en la barra de direcciones que no esté bloqueada.');
          setCameraAllowed(false);
          setLoading(null);
        });
    }

    return () => {
      stopVideo();
      setLoading(null);
      setToggleVideo(false);
    }
  }, [props.active, modelType])

  useEffect(() => {
    if (props.workspace) {
      const classes = {};
      mlDataSet.current.map((data) => {
        classes[data[1]] = data[0];
      });
      classes[stopCommandClass] = 'No reconocido';
      props.workspace.setMLClasses(classes);
    }
  }, [props.workspace, mlDataSet.current])

  useEffect(() => {
    const hideToolTipML = sessionStorage.getItem('hideToolTipML');
    if (props.workspace && importedModel && !hideToolTipML) {
      setStepsEnabled(true);
    }
  }, [props.workspace, importedModel])

  useEffect(async () => {
    if (modelType && props.iaModelJson && props.iaModelBin) {
      mlDataSet.current = [];
      setImportedModel(props.iaModelJson);
      const importedModelClasses = await loadModel(props.iaModelJson, props.iaModelBin);
      if (importedModelClasses) {
        mlDataSet.current = [];
        const nSamples = {};
        const classes = Object.keys(importedModelClasses);
        for (let i = 0; i < classes.length; i++) {
          mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
          nSamples[classes[i]] = 0;
        }
        setSamplesCount(nSamples);
        changeStep(TRAIN_STEPS.CLASSIFY);
      } else {
        setDefaultClasses();
      }
    }

  }, [modelType, props.iaModelJson, props.iaModelBin])

  useEffect(async () => {
    setModelType(props.iaModel || MODEL_TYPES.HANDS);
  }, [props.iaModel])

  useEffect(() => {
    setDefaultClasses();
  }, [])

  // TUTORIAL: Check step 1
  useEffect(async () => {
    const code = blocklyState?.project?.code || null;
    if (stepsEnabled && code) {
      if (new RegExp('voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{', 'i').test(code.replace(/\n|\s/gi, ''))) {
        steps.introJs.nextStep();
      }
    }
  }, [blocklyState?.project?.code]);

  // TUTORIAL: Check step 2
  useEffect(() => {
    if (stepsEnabled && blocklyState?.build?.building && (blocklyState?.build?.port !== '' || blocklyState?.build?.microbitForceDownload)) {
      steps.introJs.nextStep();
    }
  }, [blocklyState?.build?.building]);

  // TUTORIAL: Check step 3
  useEffect(() => {
    if (stepsEnabled && connected) {
      setTimeout(() => {
        steps.introJs.nextStep();
        setTimeout(() => {
          steps.introJs.exit();
        }, 30000);
      }, 1);
    }
  }, [connected]);

  return (
    <React.Fragment>
      <div className={`${styles.codeMLBox} ${(props.active) ? styles.active : ''} ${(props.className || '')}`}>
        <div className={styles.contentCodeBox}>
          <div className={styles.contentTop}>
            {stepsEnabled ? (
              <Steps
                ref={steps => (setSteps(steps))}
                enabled={Boolean(tutorialOptions.steps.length)}
                initialStep={0}
                steps={tutorialOptions.steps}
                onExit={stepsOnExit}
                onBeforeExit={stepsOnBeforeExit}
                options={tutorialOptions.options}
              />
            ) : (null)}
            <div className={styles.header}>
              <div className={styles.title}>{intl.formatMessage({ id: 'machineLearning.title' })}</div>
              {(!importedModel && modelManualImported && step === TRAIN_STEPS.CLASSIFY) || step === TRAIN_STEPS.TRAIN ? (
                <button id="MLModifyTraining" onClick={() => changeStep(TRAIN_STEPS.TEACH)}>{intl.formatMessage({ id: 'machineLearning.buttons.backToTraining' })}</button>
              ) : (null)}
              {importedModel ? (
                <button id="MLModifyTraining" onClick={() => window.location.href = process.env.NODE_ENV === 'production' ? 'https://train.educabot.ai' : 'https://train.staging.educabot.ai'}>
                  <img src="/images/ai/backToTrainig.svg" height={20} />
                  {intl.formatMessage({ id: 'machineLearning.buttons.backToTraining' })}
                </button>
              ) : (null)}
            </div>

            {step !== TRAIN_STEPS.TRAIN ? (
              <>
                <div class={videoWrapper}>
                  <canvas id="output"></canvas>
                  <video id="video" playsinline style={{
                    transform: 'scaleX(-1)',
                    visibility: 'hidden',
                    display: 'none',
                    width: 'auto',
                    height: 'auto',
                  }}>
                  </video>
                  {!cameraAllowed ? (
                    <button className={styles.turnOffCamera} onClick={handleAskCameraPermissions}>
                      {intl.formatMessage({ id: 'machineLearning.buttons.allowCamera' })}
                    </button>
                  ) : (
                    <button className={styles.turnOffCamera} onClick={handleToggleVideo}>
                      {toggleVideo ? (
                        <img src="/images/ai/turnOffCamera.svg" height={20} />
                      ) : (null)}
                      {intl.formatMessage({ id: toggleVideo ? 'machineLearning.buttons.turnOffCamera' : 'machineLearning.buttons.turnOnCamera' })}
                    </button>
                  )}
                </div>
                <div className={styles.videoDescription}>
                  {intl.formatMessage({ id: 'machineLearning.videoDescription' })}
                </div>
              </>
            ) : (null)}

            {step === TRAIN_STEPS.TEACH ? (
              <>
                {plot()}
                {renderClassContainers()}
                <div className={styles.teachActions}>
                  <div className={styles.teachActionsButtons}>
                    {Object.values(samplesCount).filter(sample => sample > 0).length > 1 ? (
                      <>
                        <button onClick={() => changeStep(TRAIN_STEPS.TRAIN)} disabled={(loading)}><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/ai/play.svg`} />Preparar modelo</button>
                        <button className={styles.cancel} onClick={() => handleClearAllSamples()}>Borrar muestras</button>
                      </>
                    ) : (null)}
                    {Object.values(samplesCount).filter(sample => sample > 0).length ? (
                      <button onClick={() => handleExportSamples()}>Descargar muestras</button>
                    ) : (null)}
                  </div>
                  {!Object.values(samplesCount).filter(sample => sample > 0).length ? (
                    <div className={styles.importContainer}>
                      <div><strong>Cargar muestras:</strong></div>
                      <div><label>Archivo (.txt):</label><input type="file" id="importedFiles" accept=".txt" multiple onChange={handleImportSamples} /></div>
                    </div>
                  ) : (null)}
                  <div className={styles.importContainer}>
                    <div><strong>Importar modelo descargado:</strong></div>
                    <div><label>Archivos (.json, .bin):</label><input type="file" id="importedFiles" accept=".json,.bin" multiple onChange={handleOnChangeFile} /></div>
                    {/* <div><label>JSON:</label><input type="file" id="file1" accept=".json" onChange={handleOnChangeFile} /></div>
                  <div><label>Pesos:</label><input type="file" id="file2" accept=".bin" onChange={handleOnChangeFile} /></div>
                  <button onClick={handleImportModel}>Importar modelo</button> */}
                  </div>
                </div>
              </>
            ) : (null)}
            {step === TRAIN_STEPS.TRAIN ? (
              <>
                <div className={styles.trainActions}>
                  <div><strong>Parámetros de entrenamiento:</strong></div>
                  <div className={styles.trainParams}>
                    <div><label>Épocas:</label><input type="number" value={trainingParams.epochs} onChange={(e) => setTrainingParams({ ...trainingParams, epochs: e.target.value })} /></div>
                    <div><label>Tasa de aprendizaje:</label><input className={styles.extraWidth} type="number" value={trainingParams.learningRate} onChange={(e) => setTrainingParams({ ...trainingParams, learningRate: e.target.value })} /></div>
                    <div><label>Tamaño de lote:</label><input type="number" value={trainingParams.batchSize} onChange={(e) => setTrainingParams({ ...trainingParams, batchSize: e.target.value })} /></div>
                  </div>
                  {plot()}
                  <div className={styles.trainResults}>
                    <div>Época: <span>{trainingData.epoch + 1}</span></div>
                    <div>Precisión: <span>{trainingData.acc.toFixed(2)}</span></div>
                    <div>Precisión de test: <span>{trainingData.val_acc.toFixed(2)}</span></div>
                    <div>Pérdida: <span>{trainingData.loss.toFixed(2)}</span></div>
                    <div>Pérdida de test: <span>{trainingData.val_loss.toFixed(2)}</span></div>
                  </div>
                  <div className={styles.trainActionsButtons}>
                    <button onClick={() => handleTrain()} disabled={(loading)}>Preparar modelo</button>
                    {modelTrained ? (
                      <>
                        <button onClick={() => changeStep(TRAIN_STEPS.CLASSIFY)} disabled={(loading)}>Iniciar análisis</button>
                        <button onClick={() => exportModel()} disabled={(loading)}>Descargar modelo</button>
                      </>
                    ) : (null)}
                  </div>
                </div>
              </>
            ) : (null)}
            {step === TRAIN_STEPS.CLASSIFY ? (
              <>
                {renderPredictionContainers()}
                <div className={styles.classifyActions}>
                  {!importedModel && !modelManualImported ? (
                    <>
                      <button onClick={() => changeStep(TRAIN_STEPS.TRAIN)}>Ajustar modelo</button>
                      <button onClick={() => exportModel()}>Descargar modelo</button>
                    </>
                  ) : (null)}
                </div>
              </>
            ) : (null)}
          </div>
          <div className={styles.contentBottom}>
            {loading ? (
              <div className={styles.loading}>{loading}</div>
            ) : (null)}
            <div className={`${styles.error} ${(errorMessage) ? styles.visible : ''}`}>{errorMessage}</div>
            <div className={styles.bluetooth}>
              {!connected && hasBluetooth ? (
                <button id="MLConnectBluetooth" onClick={requestConnection}>
                  <img src="/images/ai/bluetooth.svg" height={20} />
                  {intl.formatMessage({ id: 'machineLearning.buttons.connectBluetooth' })}
                </button>
              ) : (null)}
              <div className={styles.bluetoothStatus}>
                {connected ? (
                  intl.formatMessage({ id: 'machineLearning.buttons.bluetoothConnected' })
                ) : (
                  intl.formatMessage({ id: 'machineLearning.buttons.bluetoothDisconnected' })
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.toggle} role="button" tabIndex="0" onClick={onToggleChange}>
          <span className={styles.curlyBraces}><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/ai/toggleAiPanel.svg`} /></span>
          <span className={`icon-chevron-left ${styles.iconChevronLeft} ${(props.active) ? styles.rotateArrow : ''}`} />
        </div>
      </div>
    </React.Fragment >
  );
});

export default MLPanel;