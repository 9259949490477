import { AxiosResponse } from 'axios';
import { blocksApiV1 } from '../api';
import { CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';

// Types.
interface ProjectProgressBaseInfo {
  createdAt: string,
  id: number,
  isDeleted: boolean,
  projectId: number,
  step: number,
  updatedAt: string,
  userId: number,
  isCompleted: boolean;
}

// Initiation kit project info.
interface InitiationKitProjectProgressInfo extends ProjectProgressBaseInfo {
  creabotsKitId: CREABOTS_KIT_ID_TYPE.INITIATION,
  tutorialId: INITIATION_KIT_TUTORIAL_ID_TYPE,
}

export enum INITIATION_KIT_TUTORIAL_ID_TYPE {
  FIRST_STEPS = 1,
  MY_FIRST_ROBOT = 2,
  MUSICAL_BOX = 3,
  ROCK_PAPER_SCISSORS = 4,
  GUESS_THE_FUTURE = 5,
  INTELLIGENT_GARDEN = 6,
}

// Automation kit project info.
interface AutomationKitProjectProgressInfo extends ProjectProgressBaseInfo {
  creabotsKitId: CREABOTS_KIT_ID_TYPE.AUTOMATION,
  tutorialId: AUTOMATION_KIT_TUTORIAL_IDS_TYPE,
}

export enum AUTOMATION_KIT_TUTORIAL_IDS_TYPE {
  AUTOMATIC_LAMP = 1,
  INTRUDER_ALARM = 2,
  AUTOMATIC_BARRIER = 3,
  AUTOMATIC_FAN = 4,
  CLASSIFIER = 5,
  SMART_ACCESS = 6,
  SUPER_GOALKEEPER = 7,
  WEATHER_STATION = 8,

}

// Driving kit project info.
interface DrivingKitProjectProgressInfo extends ProjectProgressBaseInfo {
  creabotsKitId: CREABOTS_KIT_ID_TYPE.DRIVING,
  tutorialId: DRIVING_KIT_TUTORIAL_IDS_TYPE,
}

export enum DRIVING_KIT_TUTORIAL_IDS_TYPE {
  SMART_VEHICLE = 1,
  LUNAR_EXPLORER = 2,
  PAPER_COLLECTOR = 3,
  AUTOMATIC_HANDLING = 4,
  SOLAR_VEHICLE = 5,
}

export type ProjectProgressInfo = InitiationKitProjectProgressInfo | AutomationKitProjectProgressInfo | DrivingKitProjectProgressInfo;

interface GetProjectsProgressResponse {
  data: ProjectProgressInfo[],
  message: string,
  status: string,
}

// Function.
export const getProjectsProgress = (): Promise<ProjectProgressInfo[]> => {
  return blocksApiV1.get<void, AxiosResponse<GetProjectsProgressResponse>>('/creabots/kits/progress').then(resp => resp.data.data);
}