import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import {
  AUTOMATION_PROJECTS_TYPE,
  BLOCK_CATEGORY_TYPE,
  BOARD_ID_TYPE,
  CODE_CHECK_TYPE,
  COMMON_CLASSES,
  KIT_NAME,
  MODAL_TYPE,
  Project,
} from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import {
  getAutomationConfigForBlock,
  getAutomationElementForBlock,
} from '../config';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.automation.projects.weatherStation';
const imgBaseUrl = '/images/creabots/automation/projects/weatherStation';

export const weatherStation: Project = {
  id: 8,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/estacion-meteorologica.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({
          kitName: KIT_NAME.AUTOMATION,
          projectName: AUTOMATION_PROJECTS_TYPE.WEATHER_STATION,
          totalSteps: 13,
        }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\("abc"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(
            BLOCK_CATEGORY_TYPE.DISPLAYS,
            2
          ),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 70,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\("ESTACION"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 80,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"estacion\\"\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(
            BLOCK_CATEGORY_TYPE.DISPLAYS,
            2
          ),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 110,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"TEM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 125,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(0,1\\);lcd\\.print\\(\\"TEM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 280,
            yOffset: 125,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 395,
            yOffset: 125,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(
            BLOCK_CATEGORY_TYPE.DISPLAYS,
            2
          ),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 160,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(0,1\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 280,
            yOffset: 175,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 395,
            yOffset: 175,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.humidity\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 175,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 260,
            yOffset: 175,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(
            BLOCK_CATEGORY_TYPE.DISPLAYS,
            2
          ),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 215,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"HUM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(0,1\\);lcd\\.print\\(\\"HUM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 290,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(8,1\\);lcd\\.print\\(\\"HUM\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 405,
            yOffset: 230,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(8,1\\);lcd\\.print\\(\\"HUM\\"\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(
            BLOCK_CATEGORY_TYPE.DISPLAYS,
            2
          ),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 255,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(8,1\\);lcd\\.print\\(\\"HUM\\"\\);lcd\\.setCursor\\(0,1\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 277,
            yOffset: 278,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(8,1\\);lcd\\.print\\(\\"HUM\\"\\);lcd\\.setCursor\\(12,1\\);lcd\\.print\\(\\"abc\\"\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 395,
            yOffset: 278,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        completionCode: `voidsetup\\(\\)\\{lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\"ESTACION\\"\\);lcd\\.setCursor\\(1,1\\);lcd\\.print\\(\\"TEM\\"\\);lcd\\.setCursor\\(5,1\\);lcd\\.print\\(DHT11A0\\.temperature\\);lcd\\.setCursor\\(8,1\\);lcd\\.print\\(\\"HUM\\"\\);lcd\\.setCursor\\(12,1\\);lcd\\.print\\(DHT11A0\\.humidity\\);\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 277,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.gif`,
      },
    },
  ],
};
