import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import * as routes from '@educabot/educablocks-cosmos';
import {
  KIT_NAME,
  INITIATION_PROJECTS_TYPE,
  BOARD_ID_TYPE,
  BLOCK_CATEGORY_TYPE,
  CODE_CHECK_TYPE,
  MODAL_TYPE,
  COMMON_CLASSES,
  Project,
} from '@sections/creabots/types/kits'
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.initiation.projects.touchThePoint'
const imgBaseUrl = '/images/creabots/initiation/projects/touchThePoint/steps'

const touchThePoint: Project = {
  id: 7,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/touchThePoint/project_image.png`,
  boardId: BOARD_ID_TYPE.INITIATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/toca-el-punto.mp4'
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.INITIATION, projectName: INITIATION_PROJECTS_TYPE.TOUCH_THE_POINT, totalSteps: 13 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 7, { y: -100 }),
        completionCode: '#defineNUMLEDRGB6Adafruit_NeoPixelMODULOLEDRGB_A0\\(NUMLEDRGB,A0,NEO_GRB\\+NEO_KHZ800\\);voidsetup\\(\\)\\{MODULOLEDRGB_A0\\.begin\\(\\);\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(0,MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 7),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 65,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 2, { x: 190, ignoreBlock: true }),
        completionCodeCheck: CODE_CHECK_TYPE.VARIABLE,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.ADD_VARIABLE_BTN_CLASS,
            xOffset: 40,
            yOffset: -18,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 1),
        completionCode: 'String\\D+\\w*;[^}]*voidsetup\\(\\)\\{\\D+\\w*=;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(0,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 20,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{\\D+\\w*=;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(0,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 360,
            yOffset: 20,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{\\D+\\w*=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(0,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 435,
            yOffset: 20,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 298,
            yOffset: 98,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(1000\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 138,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 145,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 175,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0, { x: 300 }),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 183,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 425,
            yOffset: 188,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(0==0\\)\\{\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==0\\)\\{\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 105,
            yOffset: 242,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 300,
            yOffset: 242,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 2),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 75,
            yOffset: 280,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){5}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 240,
            yOffset: 290,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){2}(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){4}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 279,
            yOffset: 290,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){3}(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){3}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 317,
            yOffset: 290,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){4}(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){2}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 356,
            yOffset: 290,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){5}(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\\)\\);){1}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 395,
            yOffset: 290,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 434,
            yOffset: 290,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 58,
            yOffset: 355,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0, { y: -150 }),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=;\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 430,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 1, { y: -150 }),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=1\\+1;\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 444,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3, { y: -150 }),
        completionCode: 'int\\D+\\w*;[^}]*voidsetup\\(\\)\\{[^0]+=0;\\/\\/global_var_\\D+\\w*[^}]*\\}voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 168,
            yOffset: 442,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1, { y: -150 }),
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 472,
          },
        },
      ],
    },
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3, { y: -150 }),
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(\\D+\\w*==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 97,
            yOffset: 488,
          },
        },
      ],
    },
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(\\D+\\w*>0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 225,
            yOffset: 488,
          },
        },
      ],
    },
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(\\D+\\w*>5\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 287,
            yOffset: 488,
          },
        },
      ],
    },
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0, { y: -200 }),
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(\\D+\\w*>5\\)\\{\\D+\\w*=;\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 525,
          },
        },
      ],
    },
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0, { y: -200 }),
        completionCode: 'voidloop\\(\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);MODULOLEDRGB_A0\\.setPixelColor\\(\\D+\\w*,MODULOLEDRGB_A0\\.Color\\(\\d*,\\d*,\\d*\\)\\);MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(100\\);if\\(\\!digitalRead\\(A3\\)==1\\)\\{if\\(\\D+\\w*==2\\)\\{MODULOLEDRGB_A0\\.clear\\(\\);(MODULOLEDRGB_A0\\.setPixelColor\\([0-5],MODULOLEDRGB_A0\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);){6}MODULOLEDRGB_A0\\.show\\(\\);delayMicroseconds\\(300\\);\\}delay\\(1000\\);\\}\\D+\\w*=\\D+\\w*\\+1;if\\(\\D+\\w*>5\\)\\{\\D+\\w*=0;\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 178,
            yOffset: 532,
          },
        },
      ],
    },
    // Step 14.
    {
      step: {
        stepId: 13,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step14`,
        image: `${imgBaseUrl}/step14.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 15.
    {
      step: {
        stepId: 14,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step15`,
        image: `${imgBaseUrl}/step15.gif`,
      },
    },
  ],
}

export default touchThePoint