import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { AUTOMATION_PROJECTS_TYPE, BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getAutomationConfigForBlock, getAutomationElementForBlock } from '../config';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.automation.projects.superGoalkeeper';
const imgBaseUrl = '/images/creabots/automation/projects/superGoalkeeper';

export const superGoalkeeper: Project = {
  id: 7,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/super-arquero.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.AUTOMATION, projectName: AUTOMATION_PROJECTS_TYPE.SUPER_GOALKEEPER, totalSteps: 16 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{servo_A0\\.write\\(90\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        completionCode: 'servo_A0\\.write\\(90\\);matrix_8x8\\.clear\\(\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 115,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 92,
            yOffset: 185,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 6),
        completionCode: 'servo_A0\\.write\\(90\\);matrix_8x8\\.clear\\(\\);[^}]*if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 6),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 255,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: "case'U':servo_A0\\.write\\(90\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 300,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 345,
            yOffset: 320,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(1000\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 350,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 230,
            yOffset: 360,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(90\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 420,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 345,
            yOffset: 440,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(1000\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 470,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 230,
            yOffset: 480,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':break;case'R':servo_A0\\.write\\(90\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 500, y: -400 },
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 540,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':break;case'R':servo_A0\\.write\\(0\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -400 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 345,
            yOffset: 560,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':break;case'R':servo_A0\\.write\\(0\\);delay\\(1000\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 500, y: -400 },
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 590,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':break;case'R':servo_A0\\.write\\(0\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -400 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 230,
            yOffset: 600,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':servo_A0\\.write\\(90\\);break;case'R':servo_A0\\.write\\(0\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 500, y: -400 },
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 660,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':servo_A0\\.write\\(180\\);break;case'R':servo_A0\\.write\\(0\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -400 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 345,
            yOffset: 680,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':servo_A0\\.write\\(180\\);delay\\(1000\\);break;case'R':servo_A0\\.write\\(0\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 500, y: -400 },
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 710,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.png`,
        completionCode: "case'U':servo_A0\\.write\\(135\\);delay\\(500\\);break;case'D':servo_A0\\.write\\(45\\);delay\\(500\\);break;case'L':servo_A0\\.write\\(180\\);delay\\(500\\);break;case'R':servo_A0\\.write\\(0\\);delay\\(500\\)",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -400 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 230,
            yOffset: 720,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.gif`,
      },
    },
  ],
}
