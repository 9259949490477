import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { KitCard } from './ui/kitCard';
import { useHistory } from 'react-router-dom';
import { TextBodySmallRegular } from './ui/typographies';
import { LabsInput } from './ui/input';
import { useIntl } from 'react-intl';
import { createNewCreabotsProject } from '@modules/blockly/features/hooks';
import {
  openLabsToast,
  selectCreabots,
} from '@sections/creabots/features/creabotsSlice';
import kitOptions from '../constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SelectKitToCreateProjectModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const userKitList = useSelector(selectCreabots).kits.list;
  const filteredKits = kitOptions.map((kit) => {
    const alreadyAdded = userKitList.some((k) => k.id === kit.id);
    if (!alreadyAdded) {
      return {
        ...kit,
        isDisabled: true,
      };
    }
    return kit;
  });
  const [isSelected, setIsSelected] = useState(1);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      !isLoading &&
      name &&
      name.toLowerCase() !==
        intl.formatMessage({ id: 'creabots.untitled' }).toLowerCase()
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, isLoading]);

  const handleSelected = (kitId: number) => {
    setIsSelected(kitId);
  };

  const handleOnClose = () => {
    setName("");
    setIsSelected(1);
    onClose();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    const selectedKit = userKitList.find((k: any) => k.id === isSelected);
    if (name && selectedKit) {
      setIsLoading(true);
      createNewCreabotsProject(name, selectedKit.boardId)
        .then(id => {
          history.push(`/kits/${selectedKit.urlName}/proyecto-${id}`);
        })
        .catch(_ => {
          setIsLoading(false);
          dispatch(
            openLabsToast({
              open: true,
              message: 'creabots.dashboard.selectKitProjectModal.error',
              severity: 'error',
            })
          );
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleOnClose}
      aria-labelledby="Kit Modal"
      aria-describedby="Modal para agregar kits"
    >
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '914px',
          minWidth: '914px',
          height: 'fit-content',
          maxHeight: '550px',
        })}
      >
        <Box
          sx={theme => ({
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '914px',
            minWidth: '914px',
            height: 'fit-content',
            maxHeight: '550px',
          })}
        >
          <Box
            sx={theme => ({
              paddingX: theme.spacing(3),
              paddingY: theme.spacing(2.5),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Typography
              sx={theme => ({
                fontWeight: 500,
                color: theme.palette.text.primary,
                letterSpacing: '-0.015rem',
                fontSize: '1.125rem',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
              })}
            >
              {intl.formatMessage({
                id: 'creabots.dashboard.topbar.newProject',
              })}
            </Typography>
            <IconButton sx={{ padding: 0 }} onClick={handleOnClose}>
              <Close
                sx={theme => ({
                  height: '24px',
                  width: '24px',
                  color: theme.palette.text.secondary,
                })}
              />
            </IconButton>
          </Box>
          <TextBodySmallRegular
            paddingLeft={3}
            sx={theme => ({ color: theme.palette.text.secondary })}
          >
            {intl.formatMessage({
              id: 'creabots.dashboard.selectKitProjectModal.description',
            })}
          </TextBodySmallRegular>
          <Box
            alignItems="center"
            width="100%"
            paddingX="1.5rem"
            paddingTop="1.5rem"
          >
            <LabsInput
              onChange={handleOnChange}
              name="name"
              value={name}
              fullWidth
              placeholder={intl.formatMessage({ id: 'creabots.projectTitle' })}
            />
          </Box>
          <Box
            sx={theme => ({
              gap: theme.spacing(2.3),
              marginLeft: 0,
              width: '100%',
              height: '100%',
              padding: theme.spacing(3),
              display: 'flex',
              alignItems: 'center',
            })}
          >
            {filteredKits.map((kit: any) => (
              <Box
                key={kit.name}
                sx={{
                  flex: 1,
                  paddingLeft: '0px !important',
                  paddingTop: '0px !important',
                }}
              >
                <KitCard
                  kit={kit}
                  isSelected={isSelected === kit.id}
                  setIsSelected={handleSelected}
                  maxImageHeight="150px"
                />
              </Box>
            ))}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent={'end'}
            paddingX="1.5rem"
            paddingBottom="1.5rem"
          >
            <Box
              display="flex"
              alignItems="center"
              gap="1.5rem"
              justifySelf="end"
            >
              <Button size="small" variant="secondary" onClick={handleOnClose}>
                {intl.formatMessage({
                  id: 'common.cancel',
                })}
              </Button>
              <Button
                variant="primary"
                size="small"
                disabled={disabled}
                onClick={handleSubmit}
              >
                {intl.formatMessage({
                  id: 'common.continue',
                })}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
