/* eslint-disable dot-notation */
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCreabots } from '@sections/creabots/features/creabotsSlice';
import { INITIATION_KIT_TUTORIAL_ID_TYPE } from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import {
  TextBodyRegular,
  TextBodySmallEmphasized,
} from '../../ui/typographies';
import { UserProjectCard } from '../../userProjectsCard';
import { ProjectCard } from '../../projectsCard';
import { useCardProject } from '@sections/creabots/features/hooks/useCardProject';
import { useIntl } from 'react-intl';

interface Props {
  projectSearch: null | string;
}

export const ListProjectView: React.FC<Props> = ({ projectSearch }) => {
  const intl = useIntl();
  const { kits, loading, projects: userProjects } = useSelector(selectCreabots);
  const {
    isFirstStepProjectCompleted,
    projectsKits,
    getProjectURLByKit,
    getUserProjectURLByBoardId,
    projectProgress,
    searchedKitProjects,
    searchedUserProjects,
  } = useCardProject();

  const searchedProjects = useMemo(() => {
    return kits.selected?.id
      ? searchedKitProjects(projectSearch)
      : searchedUserProjects(projectSearch);
  }, [kits.selected?.id, projectSearch]);

  return (
    <Box
      sx={theme => ({
        width: '100%',
        display: 'flex',
        alignItems: searchedProjects.length === 0 ? 'center' : 'flex-start',
        justifyContent: searchedProjects.length === 0 ? 'center' : 'flex-start',
        flexDirection: 'column',
        gap: theme.spacing(1),
      })}
    >
      {
        <>
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              paddingX: theme.spacing(1),
            })}
          >
            <TextBodySmallEmphasized
              sx={theme => ({
                color: theme.palette.text.secondary,
                width: '100%',
              })}
            >
              {intl.formatMessage({
                id: 'creabots.dashboard.projectContainer.tableHead.title',
              })}
            </TextBodySmallEmphasized>
            {kits.selected?.id !== 0 ? (
              <TextBodySmallEmphasized
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  width: '30%',
                })}
              >
                {intl.formatMessage({
                  id: 'creabots.dashboard.projectContainer.tableHead.progress',
                })}
              </TextBodySmallEmphasized>
            ) : (
              <TextBodySmallEmphasized
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  width: '30%',
                })}
              >
                Editado el
              </TextBodySmallEmphasized>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: theme => theme.spacing(1.5),
            }}
          >
            {!kits.selected?.id && !loading
              ? searchedProjects.map(project => {
                  return (
                    <UserProjectCard
                      key={crypto.randomUUID()}
                      type="item"
                      project={project}
                      title={project.title}
                      description={project.updatedAt}
                      kitName={
                        projectsKits.find(
                          kit => kit.boardId === project.jsonBody?.board?.id
                        )?.name || ''
                      }
                      canvas={{
                        canvas: project.jsonBody.canvas,
                        canvasCss: project.canvasCss,
                        canvasBBoxX: project.jsonBody.canvasBBoxX,
                        canvasBBoxY: project.jsonBody.canvasBBoxY,
                        canvasBBoxWidth: project.jsonBody.canvasBBoxWidth,
                        canvasBBoxHeight: project.jsonBody.canvasBBoxHeight,
                      }}
                      redirectTo={getUserProjectURLByBoardId(
                        project.jsonBody?.board?.id || 1,
                        project.id
                      )}
                    />
                  );
                })
              : ''}
            {kits.selected?.id && searchedProjects.length
              ? searchedProjects.map((project) => {
                  const selectedKitId = kits.selected?.id;
                  const progressInfo = kits.progress.find(
                    (p: any) =>
                      p.creabotsKitId == selectedKitId &&
                      p.tutorialId == project.id &&
                      !p.isDeleted
                  );

                  const restartProgressInfo = {
                    projectId: progressInfo?.projectId,
                    tutorialId: progressInfo?.tutorialId,
                    creabotsKitId: selectedKitId,
                  };

                  const canBlockCard =
                    (project.id !== INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS && !isFirstStepProjectCompleted) ||
                    (!isFirstStepProjectCompleted && selectedKitId !== CREABOTS_KIT_ID_TYPE.INITIATION);

                  return (
                    <ProjectCard
                      key={crypto.randomUUID()}
                      type="item"
                      title={project.name}
                      description={project.description}
                      image={project.image}
                      isBlocked={canBlockCard}
                      isCustom={false}
                      redirectTo={getProjectURLByKit(
                        kits.selected.id,
                        project.id
                      )}
                      progress={projectProgress(project.id, searchedProjects)}
                      restartProgressInfo={restartProgressInfo}
                    />
                  );
                })
              : ''}
          </Box>
        </>
      }
      {kits.selected.id === 0 && userProjects.length === 0 ? (
        <Box display="flex" justifyContent="center">
          <TextBodyRegular itemType="info">
            {intl.formatMessage({
              id: 'creabots.dashboard.projectContainer.startToCreate',
            })}
          </TextBodyRegular>
        </Box>
      ) : (
        <>
          {kits.selected.id === 0 && searchedProjects.length === 0 ? (
            <Box display="flex" justifyContent="center">
              <TextBodyRegular itemType="info">
                {intl.formatMessage({
                  id: 'creabots.dashboard.projectContainer.withoutResults',
                })}
              </TextBodyRegular>
            </Box>
          ) : null}
        </>
      )}

      {kits.selected.id !== 0 && searchedProjects.length === 0 ? (
        <Box display="flex" justifyContent="center">
          <TextBodyRegular itemType="info">
            {intl.formatMessage({
              id: 'creabots.dashboard.projectContainer.withoutResults',
            })}
          </TextBodyRegular>
        </Box>
      ) : null}
    </Box>
  );
};
